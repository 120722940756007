<template>
  <div class="main">
    <div
      class="retract"
      @click="clickRetract(!identifying)"
      :class="{ collapsed: !identifying }"
    >
      <i :class="retract"></i>
    </div>
<!--    <div-->
<!--        class="retract2"-->
<!--        :class="{ collapsed: !identifying }"-->
<!--    >-->
<!--      <i class="el-icon-switch-button"></i>-->
<!--    </div>-->
    <Menu
      class="menu"
      :src="src"
      :ac="!identifying"
      :class="{ collapsed: !identifying }"
    ></Menu>
    <router-view class="ers" :class="{ collapsed: !identifying }" />
  </div>
</template>

<script>
import Menu from "../views/HomeView";

export default {
  components: {
    Menu,
  },
  data() {
    return {
      retract: "el-icon-s-fold",
      identifying: true,
      src: require("@/static/img/logoword.png"),
    };
  },
  created() {},
  mounted() {},
  methods: {
    clickRetract(e) {
      this.identifying = e;
      if (e) {
        this.src = require("@/static/img/logoword.png");
        this.retract = "el-icon-s-fold";
      } else {
        this.src = require("@/static/img/logo1.png");
        this.retract = "el-icon-s-unfold";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  //width: 100%;
  height: 97%;
  display: flex;
}

.menu {
  z-index: 22;
  width: 15%;
  height: 100%;
  position: fixed;
  left: 0;
  // top: 3%;
  top: 0;
  &.collapsed {
    width: 5%;

    ::v-deep .menu_text span {
      display: none;
    }

    ::v-deep .menu_text i {
      padding-left: 35%;
    }
    ::v-deep .menu_text div.custom-icon {
      padding-left: 35%!important;
    }
  }
}

.ers {
  width: 85%;
  height: 100%;

  &.collapsed {
    width: 95%;
    height: 100%;
  }
}

.retract {
  z-index: 999;
  width: 3.5%;
  height: 3.5%;
  position: fixed;
  //top: 3%;
  left: 15%;
  background: #fff;
  font-size: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  top: 0;

  &.collapsed {
    left: 5%;
  }
}


.retract:hover {
  z-index: 999;
  width: 3.5%;
  height: 3.5%;
  position: fixed;
  //top: 3%;
  left: 15%;
  background: #6AA56B;
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  top: 0;

  &.collapsed {
    left: 5%;
  }
}

.retract2 {
  z-index: 999;
  width: 3.5%;
  height: 3.5%;
  position: fixed;
  top: 3%;
  left: 15%;
  background: #fff;
  font-size: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  //top: 0;

  &.collapsed {
    left: 5%;
  }
}
.retract2:hover {
  z-index: 999;
  width: 3.5%;
  height: 3.5%;
  position: fixed;
  top: 3%;
  left: 15%;
  background: #6AA56B;
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  //top: 0;

  &.collapsed {
    left: 5%;
  }
}
@media screen and  (max-width: 1024px) and (min-width:768px){
  .retract {
    display: none !important;
  }
}
@media screen and (max-width:768px){
  .retract {
    display: none !important;
  }

}



</style>
